.container {
    padding: 4% 6%;
    width: 100%;
    display: flex;
    gap: 10%;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    position: relative;
    box-sizing: border-box;
    .leftContainer {
        width: 50%;

        .titleContainer {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 36px !important;
                font-weight: 700 !important;
            }

            .content {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 16px !important;
                font-weight: 400 !important;
            }
        }

        .contactBox {
            img {
                height: 68px;
                width: 68px;
            }

            display: flex;
            gap: 20px;

            .contactTitle {
                font-family: 'Poppins', sans-serif !important;
                color: #000000 !important;
                font-size: 18px !important;
                font-weight: 600 !important;
            }

            .contactValue {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 16px !important;
                font-weight: 400 !important;
            }
        }

        .contactBigBox {
            padding: 20px 0 !important;
        }

    }

    .rightContainer {
        width: 50%;
        padding: 2% 6%;
        border: 1px solid rgba(255, 152, 0, 0.2);
        box-shadow: 6.52px 12.11px 30.74px 0px #FF98001A !important;
        border-radius: 18px;

        .contactUsTitle {
            color: #263248;
            font-family: 'Poppins', sans-serif !important;
            color: #263248 !important;
            font-size: 28px !important;
            font-weight: 700 !important;
            text-align: center;

        }

        .inputTitle {
            color: #344054 !important;
            font-family: 'Poppins', sans-serif !important;
            font-size: 13px !important;
            font-weight: 400 !important;
            padding-bottom: 3px;
        }

        .inputBox {
            height: 40px;
            border: 0.9px solid rgba(208, 213, 221, 1);
            border-radius: 7.4px;
            width: 100%;
            outline: none !important;
            padding-left: 10px;

            &::placeholder {
                color: #667085 !important;
                font-size: 13px !important;
                font-weight: 400 !important;
                font-family: 'Poppins', sans-serif !important;

            }
        }

        button {
            height: 54px;
            background-color: #263248;
            font-family: 'Poppins', sans-serif !important;
            color: #FFFFFF !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            width: 100%;
            border-radius: 7.4px;
            text-transform: uppercase;
            border: none !important;
        }
    }
    .bgLogo1 {
        position: absolute;
        bottom: 46%;
        right: 50%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
    .bgLogo2 {
        position: absolute;
        top: 4%;
        left: -5%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
    .bgLogo3 {
        position: absolute;
        bottom: 14%;
        right: -4%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
    
}

@media (max-width: 780px) {
    .container {
        flex-direction: column !important;

        .leftContainer {
            width: 100% !important;

            .titleContainer {
                gap: 10px !important;

                .title {
                    text-align: center !important;
                    font-size: 24px !important;
                }

                .content {
                    text-align: center !important;
                    font-size: 10px !important;
                }
            }

            .contactBigBox {
                padding: 0 8% !important;

                .contactBox {
                    img {
                        height: 40px !important;
                        width: 40px !important;
                    }

                    .contactTitle {
                        font-size: 12px !important;
                    }

                    .contactValue {
                        font-size: 10px !important;

                    }
                }

            }
        }

        .rightContainer {
            width: 100% !important;
            margin-top: 26px !important;
            padding-bottom: 20px !important;

            .contactUsTitle {
                font-size: 22px !important;
            }

            button {
                height: 36px !important;
                font-size: 10px !important;
            }
        }

    }
    .bgLogo1 ,.bgLogo2 , .bgLogo3{
        display: none !important;
    }
}