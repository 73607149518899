.container {
    padding: 1% 0;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    align-items: center;
    z-index: 1000;
    box-shadow: 0px 4px 4px 0px #00000014;

    .innerContainer {
        display: flex;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 20px;
        align-items: center;
        justify-content: space-between;

        .imageContainer {
            background-color: white;
    
            img {
                height: 56px;
                width: 200px;
            }
        }
    
        .navContainer {
            display: flex;
            align-items: center !important;
            justify-content: center;
            padding: 0;
            margin: 0 auto;
            flex: 1;
    
            .linkActiveText {
                color: #263248 !important;
                text-transform: capitalize !important;
                font-family: 'Poppins', sans-serif !important;
                font-weight: 500 !important;
                font-size: 16px !important;
                text-decoration: none !important;
                border-bottom: 3px solid #FF9800;
                padding: 0 2px 1.5px 2px !important;
                cursor: pointer;
            }
    
            .linkInActiveText {
                color: #263248 !important;
                text-transform: capitalize !important;
                font-family: 'Poppins', sans-serif !important;
                font-weight: 500 !important;
                font-size: 16px !important;
                text-decoration: none !important;
                padding: 0 2px 1.5px 2px !important;
                cursor: pointer;
    
            }
    
            ul {
                list-style: none;
                display: flex !important;
                gap: 80px;
                text-decoration: none !important;
                margin: 0;
    
                li {
                    color: #263248 !important;
                    text-transform: capitalize !important;
                    font-family: 'Poppins', sans-serif !important;
                    font-weight: 500 !important;
                    font-size: 16px !important;
                }
            }
        }
    
    
    
    
        .buttonContainer {
            display: flex;
            justify-content: end;
            margin-left: auto;
            flex: 0;
    
            .contactButton {
                background-color: #263248 !important;
                height: 48px !important;
                width: 156px !important;
                color: #FFFFFF !important;
                text-transform: capitalize !important;
                font-family: 'Nunito', sans-serif !important;
                font-weight: 600 !important;
                font-size: 16px !important;
                border-radius: 6px;
            }
        }
    }
}

.mobileContentBox {
    display: flex;
    flex-direction: column !important;

    .mobileContent {
        display: flex !important;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        padding: 30px 0 40px 0;

        .title {
            color: #263248 !important;
            text-transform: capitalize !important;
            font-family: 'Poppins', sans-serif !important;
            font-weight: 500 !important;
            font-size: 16px !important;
        }
    }

    .mobileImageContaiber {
        img {
            height: 36px;
            width: 150px !important;
        }
    }
}

@media (max-width: 1200px) {
    .container {
        padding: 2% 4% !important;

        .imageContainer {
            width: 60%;

            img {
                height: 36px !important;
                width: 150px !important;
            }
        }

        .navContainer,
        .buttonContainer {
            display: none !important;
        }

        .menuContainer {
            display: block !important;
            width: 40% !important;
            display: flex !important;
            justify-content: flex-end !important;
            align-items: center !important;
        }
    }

}

@media (max-width: 780px) {
    .container {
        padding: 2% 0 !important;

        .imageContainer {
            width: 60%;

            img {
                height: 36px !important;
                width: 150px !important;
            }
        }

        .navContainer,
        .buttonContainer {
            display: none !important;
        }

        .menuContainer {
            display: block !important;
            width: 40% !important;
            display: flex !important;
            justify-content: flex-end !important;
            align-items: center !important;
        }
    }

}