.container {
    overflow: hidden !important;
    position: relative;
    box-sizing: border-box;

    .contentBigContainer {
        padding: 0 10% 4% 10%;
    }

    .logoContainer {
        img {
            width: 100%;
        }

    }

    .gridContainer {
        display: flex;
        justify-content: center;
        z-index: 2;
    }

    .servicesTitle {
        font-family: 'Poppins', sans-serif !important;
        color: #263248 !important;
        font-size: 45px;
        font-weight: 700 !important;
        text-align: center !important;
        width: 100% !important;
        padding: 3%;
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10%;
        border: 1px solid rgba(219, 219, 219, 1);
        border-radius: 12px;
        background-color: #EFF4FF;
        height: 260px;
        box-shadow:
            6px 5px 17px 0px #0000001A,
            23px 21px 32px 0px #00000017,
            53px 47px 43px 0px #0000000D,
            94px 84px 50px 0px #00000003,
            146px 132px 55px 0px #00000000 !important;

        .header {
            display: flex;
            align-items: center;
            gap: 18px;
        }

        .headerTitle {
            font-family: 'Poppins', sans-serif !important;
            color: #000000 !important;
            font-size: 22px;
            font-weight: 400 !important;
        }

        .content {
            font-family: 'Poppins', sans-serif !important;
            color: #000000 !important;
            font-size: 16px;
            font-weight: 400 !important;
            transition: background-color 0.3s ease, color 0.3s ease;
        }

        &:hover {
            background-color: #263248 !important;
            transition: background-color 0.6s ease;

            .content {
                color: white !important;
            }

            .headerTitle {
                color: white !important;
            }
        }
    }

    .bgLogo1 {
        position: absolute;
        bottom: 21%;
        right: 43.6%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }

    .bgLogo2 {
        position: absolute;
        bottom: 41%;
        left: 6.4%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
}


@media (max-width: 780px) {
    .servicesTitle {
        font-size: 18px !important;
    }

    .contentContainer {
        height: fit-content !important;

        .headerTitle {
            font-size: 16px !important;
        }

        .content {
            font-size: 12px !important;
        }
    }
}

@media (max-width: 1080px) {
    .servicesTitle {
        font-size: 18px !important;
    }

    .contentContainer {
        height: fit-content !important;

        .headerTitle {
            font-size: 16px !important;
        }

        .content {
            font-size: 12px !important;
        }
    }
}