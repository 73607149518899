.container {
    overflow: hidden !important;
    position: relative;
    box-sizing: border-box;

    .aboutHeaderContainer {
        padding: 3% 6%;

        .headerBox {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .headerTitle {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 56px;
                font-weight: 800 !important;
                text-align: center;
            }

            .headerContent {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 18px;
                font-weight: 400 !important;
                text-align: center;

            }
        }

        .headerSecondBox {
            .leftHeaderBox {
                background-color: #263248;
                padding: 8%;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 20px;
                height: 330px;

                .leftHeaderTitle {
                    font-family: 'Poppins', sans-serif !important;
                    color: #FFFFFF !important;
                    font-size: 24px;
                    font-weight: 700 !important;
                }

                .leftHeaderContent {
                    font-family: 'Poppins', sans-serif !important;
                    color: #FFFFFF !important;
                    font-size: 16px;
                    font-weight: 400 !important;
                }
            }

            .rightHeaderBox {
                background-color: #FF9800;
                padding: 8%;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                height: 330px;
                justify-content: center;


                .rightHeaderTitle {
                    font-family: 'Poppins', sans-serif !important;
                    color: #FFFFFF !important;
                    font-size: 24px;
                    font-weight: 700 !important;
                }

                .rightHeaderContent {
                    font-family: 'Poppins', sans-serif !important;
                    color: #FFFFFF !important;
                    font-size: 16px;
                    font-weight: 400 !important;
                }
            }
        }
    }

    //ceo container
    .ceoContainer {
        width: 100%;

        .ceoHeaderContainer {
            width: 100%;
            display: flex;
            height: 340px;

            .ceoImageBox {
                width: 40%;
                display: flex;
                justify-content: flex-end;

            }

            .ceoContentBox {
                width: 60%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 30px;

                .ceoContentTitle {
                    font-family: 'Poppins', sans-serif !important;
                    color: #263248 !important;
                    font-size: 42px;
                    font-weight: 600 !important;
                }

                .ceoContentContent {
                    font-family: 'Figtree', sans-serif !important;
                    color: #263248 !important;
                    font-size: 32px;
                    font-weight: 600 !important;
                }
            }

        }

        .ceoFooterContainer {
            background: url('../../assets/Images/CeoContainerBgImage.png');
            background-repeat: no-repeat;
            background-size: cover; // Adjusts the image to cover the container
            background-position: center;
            height: 340px;
            background-color: #263248;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 20px 40px;

            .ceoFooterContent {
                font-family: 'Poppins', sans-serif !important;
                color: #FFFFFF !important;
                font-size: 20px;
                font-weight: 400 !important;
                line-height: 40px;
            }

        }
    }


    //location Container{
    .locationContainer {
        width: 100%;
        padding: 4% 14%;

        .mapContentContainer {
            width: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            gap: 30px;

            .mapTitle {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 45px;
                font-weight: 700 !important;
            }

            .mapContent {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 18px;
                font-weight: 400 !important;
            }
        }

        .mapContainer {
            width: 100%;

            img {
                width: 100%;
                height: 430px;
            }
        }
    }

    //stroy container
    .storyContainer {
        padding: 2% 7% 15% 7%;

        .storyImageContainer {
            position: relative;

            img {
                width: 100%;
                padding-left: 50px;
                height: 520px;
            }

            .storyContentContainer {
                position: absolute;
                bottom: -160px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                background-color: #263248;
                width: fit-content;
                padding: 2% 2% 2% 4%;
                border-radius: 22px;
                width: 67%;

                .storyTitle {
                    font-family: 'Poppins', sans-serif !important;
                    color: #FFFFFF !important;
                    font-size: 24px;
                    font-weight: 700 !important;

                }

                .storyContent {
                    font-family: 'Poppins', sans-serif !important;
                    color: #FFFFFF !important;
                    font-size: 16px;
                    font-weight: 400 !important;
                    letter-spacing:1px;
                }
            }
        }

    }

    //diversityContainer
    .diversityContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 0% 6% 4% 6%;

        .dicersityTitle {
            font-family: 'Poppins', sans-serif !important;
            color: #263248 !important;
            font-size: 40px;
            font-weight: 700 !important;
            text-align: center;
        }

        .dicersityContent {
            font-family: 'Poppins', sans-serif !important;
            color: #263248 !important;
            font-size: 18px;
            font-weight: 400 !important;
            text-align: center;

        }

    }
    .bgLogo1 {
        position: absolute;
        top: 4%;
        left: -2%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
    .bgLogo2 {
        position: absolute;
        top: 28%;
        right: -2%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
    .bgLogo3 {
        position: absolute;
        top: 41%;
        left: 2%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
    .bgLogo4 {
        position: absolute;
        top: 63%;
        right: 2%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
    .bgLogo5 {
        position: absolute;
        bottom: 10%;
        right: 6%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
    .bgLogo6 {
        position: absolute;
        bottom: 8%;
        left: -4%;
        z-index: -2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 154px;
            width: 168px;
        }
    }
}

@media (max-width: 1200px) {
    .headerTitle {
        font-size: 34px !important;
    }

    .headerContent {
        font-size: 16px !important;
    }

    .headerSecondBox {
        .leftHeaderBox {
            height: fit-content !important;

            .leftHeaderTitle {
                font-size: 20px !important;

            }

            .leftHeaderContent {
                font-size: 14px !important;

            }
        }

        .rightHeaderBox {
            height: fit-content !important;

            .rightHeaderTitle {
                font-size: 20px !important;

            }

            .rightHeaderContent {
                font-size: 14px !important;

            }

        }
    }

    .ceoContainer {
        .ceoHeaderContainer {
            flex-direction: column !important;

            .ceoImageBox {
                width: 100% !important;
                justify-content: center !important;

                img {
                    height: 180px !important;
                    width: 180px !important;
                }
            }

            .ceoContentBox {
                width: 100% !important;
                text-align: center !important;
                margin-top: 20px !important;
                gap: 10px !important;

                .ceoContentTitle {
                    font-size: 28px !important;

                }

                .ceoContentContent {
                    font-size: 20px !important;
                }
            }
        }

        .ceoFooterContainer {
            height: fit-content !important;
            padding: 20px 28px !important;

            .ceoFooterContent {
                font-size: 14px !important;
            }
        }
    }

    .locationContainer {
        .mapTitle {
            font-size: 24px !important;
        }

        .mapContent {
            font-size: 16px !important;
        }

        .mapContainer {
            img {
                height: fit-content !important;
            }
        }
    }

    .storyContainer {
        padding: 2% 7% 4% 7% !important;
        
        .storyImageContainer {
            img {
                display: none !important;
            }

            position: initial !important;

            .storyContentContainer {
                position: initial !important;
                width: 100% !important;

                .storyTitle {
                    font-size: 16px !important;
                    text-align: center !important;
                }

                .storyContent {
                    font-size: 10px !important;
                    text-align: center !important;

                }
            }
        }
    }

    .diversityContainer {
        gap: 10px !important;
        padding: 0 6% 4% 6%!important;
        .dicersityTitle {
            font-size: 22px !important;
        }

        .dicersityContent {
            font-size: 12px !important;
        }
    }
    .bgLogo1 , .bgLogo2 , .bgLogo3 , .bgLogo4 , .bgLogo5 , .bgLogo6{
        display: none !important;
    }
}

@media (max-width: 780px) {
    .headerTitle {
        font-size: 28px !important;
    }

    .headerContent {
        font-size: 12px !important;
    }

    .headerSecondBox {
        .leftHeaderBox {
            height: fit-content !important;

            .leftHeaderTitle {
                font-size: 18px !important;

            }

            .leftHeaderContent {
                font-size: 12px !important;

            }
        }

        .rightHeaderBox {
            height: fit-content !important;

            .rightHeaderTitle {
                font-size: 18px !important;

            }

            .rightHeaderContent {
                font-size: 12px !important;

            }

        }
    }

    .ceoContainer {
        .ceoHeaderContainer {
            flex-direction: column !important;

            .ceoImageBox {
                width: 100% !important;
                justify-content: center !important;

                img {
                    height: 180px !important;
                    width: 180px !important;
                }
            }

            .ceoContentBox {
                width: 100% !important;
                text-align: center !important;
                margin-top: 20px !important;
                gap: 10px !important;

                .ceoContentTitle {
                    font-size: 28px !important;

                }

                .ceoContentContent {
                    font-size: 20px !important;
                }
            }
        }

        .ceoFooterContainer {
            height: fit-content !important;
            padding: 20px 28px !important;

            .ceoFooterContent {
                font-size: 10px !important;
            }
        }
    }

    .locationContainer {
        .mapTitle {
            font-size: 22px !important;
        }

        .mapContent {
            font-size: 12px !important;
        }

        .mapContainer {
            img {
                height: fit-content !important;
            }
        }
    }

    .storyContainer {
        .storyImageContainer {
            img {
                display: none !important;
            }

            position: initial !important;

            .storyContentContainer {
                position: initial !important;
                width: 100% !important;

                .storyTitle {
                    font-size: 16px !important;
                    text-align: center !important;
                }

                .storyContent {
                    font-size: 10px !important;
                    text-align: center !important;

                }
            }
        }
    }

    .diversityContainer {
        gap: 10px !important;
        padding: 0 6% 4% 6%!important;
        .dicersityTitle {
            font-size: 22px !important;
        }

        .dicersityContent {
            font-size: 12px !important;
        }
    }
    .bgLogo1 , .bgLogo2 , .bgLogo3 , .bgLogo4 , .bgLogo5 , .bgLogo6{
        display: none !important;
    }
}