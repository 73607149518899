.container {
    background-color: #0F1B32;
    padding: 40px 0 0;

    .gridContainer {
        padding: 0 20px 40px;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }

    .followUs {
        width: fit-content !important;
        background: linear-gradient(90deg, #1E293B, #07224F);
        border-radius: 0 4px 4px 0;

        .followUsTitle {
            font-family: 'Poppins', sans-serif;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 400;
            padding: 3px 10px 1px 10px;
        }
    }

    .quickLinksContainer,
    .addressContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .title {
        font-family: 'Poppins', sans-serif;
        color: #FF9800;
        font-size: 18px;
        font-weight: 400;
        opacity: 0.8;

    }

    .value {
        font-family: 'Poppins', sans-serif;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
            opacity: 1 !important;
        }
    }

    .sponcerContainer {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px !important;

    }

    .copyrightContainer {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        gap: 4px;
        padding: 20px;

        .copyrightMessage {
            font-family: 'Poppins', sans-serif;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 400;
            opacity: 0.8;
        }

        .copyrightLink {
            font-family: 'Poppins', sans-serif;
            color: #FF9800;
            font-size: 16px;
            font-weight: 400;
            opacity: 0.8;

        }
    }
}



@media (max-width: 780px) {
    .container {
        padding: 8% !important;

        .gridContainer {
            gap: 20px;
        }

        .sponcerContainer {
            justify-content: center !important;
            text-align: center;
            padding: 20px 0 0 !important;
        }

        .copyrightContainer {
            display: block;

            .copyrightMessage,
            .copyrightLink {
                font-size: 12px !important;
                text-align: center;
            }
        }
    }
}