.container {
    overflow: hidden !important;
    position: relative;
    box-sizing: border-box;

    .innContainer {
        width: 100%;
        max-width: 1280px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .tcenter {
        text-align: center;
    }

    .dFlex {
        display: flex;
        gap: 3%;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 30px;
        }

        div {
            flex: 1;
        }
    }

    //Header Container Or Intro Container
    .headerContainer {
        background:
            linear-gradient(rgba(38, 50, 72, 0.6), rgba(38, 50, 72, 0.6)),
            url('../../assets/Images/HomeBgImage.png') no-repeat center center;
        height: 80vh;
        width: 100%;
        background-size: cover; // Adjusts the image to cover the container
        background-position: center; // Centers the image
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;

        .leverTitle {
            font-family: 'Poppins', sans-serif !important;
            color: #FFFFFF !important;
            font-size: 60px;
            font-weight: 600 !important;
            text-align: center !important;
            line-height: 80px !important;

            @media (max-width: 768px) {
                line-height: 24px !important;
            }

        }

        span {
            font-family: 'Poppins', sans-serif !important;
            color: #FF9800 !important;
            font-size: 60px;
            font-weight: 300 !important;
        }

        .adventureTitle {
            font-family: 'Poppins', sans-serif !important;
            color: #FFFFFF !important;
            font-size: 20px;
            font-weight: 400 !important;
            line-height: 28px !important;
            text-align: center !important;

            @media (max-width: 768px) {
                line-height: 20px !important;
            }
        }

        .nglccImageContainer {
            @media (max-width: 768px) {
                margin: 0;

                img {
                    width: 80px !important;
                    height: auto !important;
                }
            }
        }

        .nglccImageContainer {
            img {
                height: 70px;
                width: 192px;
            }
        }

        .buttonsGroup {
            display: flex;
            gap: 24px;
            width: 100%;
            justify-content: center;

            @media (max-width: 768px) {
                margin: 0;
                gap: 10px;
            }

            .buttonOne {
                height: 50px;
                border-radius: 8px;
                font-family: 'Poppins', sans-serif !important;
                color: #FFFFFF !important;
                font-size: 15px;
                font-weight: 600 !important;
                text-transform: capitalize !important;
                border: 2px solid #FFFFFF;
                width: 19%;
            }

            .buttonTwo {
                height: 50px;
                border-radius: 8px;
                font-family: 'Poppins', sans-serif !important;
                color: #FFFFFF !important;
                font-size: 15px;
                font-weight: 600 !important;
                text-transform: capitalize !important;
                background-color: #FF9800 !important;
                border: 2px solid #FF9800;
                width: 19%;
            }
        }

    }

    //Second Container Or AdvantEdge Container
    .advantEdgeContainer {
        display: flex;
        width: 100%;
        padding: 4% 6%;
        gap: 3%;
        position: relative;



        .bgLogo1 {
            position: absolute;
            top: 10%;
            right: -3%;
            z-index: -2 !important;
            transform: rotate(4deg) !important;

            img {
                height: 180px;
                width: 180px;
            }
        }

        .bgLogo2 {
            position: absolute;
            bottom: -4%;
            right: 28%;
            z-index: -2 !important;
            transform: rotate(-60deg) !important;

            img {
                height: 180px;
                width: 180px;
            }
        }


        .advImageContainer {
            width: 100%;
            height: 100%;

            img {
                max-height: 100% !important;
                max-width: 100%;
                object-fit: cover;
            }
        }

        .contentContainer {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;

            .headline {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 45px;
                font-weight: 700 !important;
                line-height: 54px;
            }

            .contentOne,
            .contentTwo {
                font-family: 'Poppins', sans-serif !important;
                color: #20242D !important;
                font-size: 16px;
                font-weight: 400 !important;
                line-height: 24px !important;
            }

            .learnMoreButton {
                background-color: #263248 !important;
                width: fit-content !important;
                height: 48px;
                padding: 0 30px;
                text-transform: capitalize;
                color: #FFFFFF;
                border-radius: 6px !important;
                font-size: 16px;
                font-weight: 600;
                font-family: 'Poppins', sans-serif !important;
                margin-top: 10px !important;

            }
        }
    }


    //leveraging container
    .leveragingContainer {
        width: 100%;
        display: flex;
        padding: 4% 6%;
        height: 650px;
        background-color: #263248;
        gap: 6%;
        position: relative;

        .bgLogo5 {
            position: absolute;
            top: 8%;
            left: 25.4%;
            z-index: 1 !important;
            transform: rotate(4deg) !important;

            img {
                height: 180px;
                width: 180px;
            }
        }

        .bgLogo6 {
            position: absolute;
            top: 10%;
            right: 10%;
            z-index: -1 !important;
            transform: rotate(4deg) !important;

            img {
                height: 180px;
                width: 180px;
            }
        }

        .leverageRightContainer {
            width: 50%;
            display: flex;

            gap: 30px;

            .leveImgOneContainer {
                width: 50%;
                display: flex;
                flex-direction: column;
                z-index: 2;

                img {
                    height: auto;
                    width: 295px;
                }

                .whiteSpace {
                    background-color: #FFFFFF;
                    height: 20px;
                    width: 295px;
                }

                .orangeBox {
                    background-color: #FF9800;
                    height: 178px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    width: 295px;
                    border-radius: 0 0 25px 25px;

                    .yearsCount {
                        font-family: 'Poppins', sans-serif !important;
                        color: #263248 !important;
                        font-size: 60px;
                        font-weight: 800 !important;

                    }

                    span {
                        font-weight: 600 !important;
                        font-size: 40px;
                        font-family: 'Poppins', sans-serif !important;
                        color: #263248 !important;
                    }

                    .yearsTitle {
                        font-family: 'Poppins', sans-serif !important;
                        color: #263248 !important;
                        font-size: 18px;
                        font-weight: 700 !important;
                    }

                }
            }

            .leveImgTwoContainer {
                width: 50%;
                padding-top: 50px;
                z-index: 2;

                img {
                    height: 100%;
                    width: 295px;
                }
            }


        }

        .leverageLeftContainer {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 18px;

            .levageTitle {
                font-family: 'Poppins', sans-serif !important;
                color: #FFFFFF !important;
                font-size: 45px;
                font-weight: 700 !important;
                opacity: 0.8;
            }

            .levageParah {
                font-family: 'Poppins', sans-serif !important;
                color: #DDDDDD !important;
                font-size: 16px;
                font-weight: 400 !important;
            }

        }
    }

    //Empover Container

    .empowerContainer {
        width: 100%;
        display: flex;
        padding: 4% 6%;
        height: 700px;
        // gap: 20%;
        background-color: #FFFFFF;

        .leftContainer {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 30px;

            .empowerTitle {
                font-family: 'Poppins', sans-serif !important;
                color: #263248 !important;
                font-size: 45px;
                font-weight: 700 !important;

                span {
                    font-family: 'Poppins', sans-serif !important;
                    color: #FF9800 !important;
                    font-size: 45px;
                    font-weight: 300 !important;
                }
            }

            .empowerContent {
                font-family: 'Poppins', sans-serif !important;
                color: #000000 !important;
                font-size: 16px;
                font-weight: 400 !important;
            }

            .viewAllButton {
                width: fit-content;
                padding: 0 40px;
                height: 54px;
                background-color: #FF9801 !important;
                font-family: 'Poppins', sans-serif !important;
                font-size: 16px;
                text-transform: capitalize !important;
                font-weight: 500 !important;
                color: #FFFFFF !important;
                border-radius: 8px !important;
                margin-top: 16px !important;

            }
        }

        .rightContainer {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-height: 100% !important;
                max-width: 100%;
                object-fit: cover;
            }
        }
    }


    .bgLogo3 {
        position: absolute;
        bottom: 16% !important;
        left: 36%;
        z-index: 2 !important;
        transform: rotate(2deg) !important;

        img {
            height: 180px;
            width: 180px;
        }
    }

    .bgLogo4 {
        position: absolute;
        bottom: 0%;
        left: -4%;
        z-index: 2 !important;
        transform: rotate(4deg) !important;

        img {
            height: 180px;
            width: 180px;
        }
    }



}

@media (max-width: 1200px) {
    .headerContainer {
        gap: 10px !important;
        height: fit-content !important;
        padding: 8% 6% !important;
        background-position: left !important;

        .leverTitle {
            font-size: 30px !important;
            line-height: 40px !important;
        }

        span {
            font-size: 20px !important;

        }

        .adventureTitle {
            font-size: 12px !important;

        }

        .nglccImageContainer {
            img {
                height: 50px;
                width: 150px;
            }
        }

        .buttonsGroup {
            padding: 20px 40px;

            .buttonOne,
            .buttonTwo {
                width: 30% !important;
                font-size: 12px !important;
            }

        }
    }

    .advantEdgeContainer {
        padding: 8% 6% !important;
        height: fit-content !important;

        .contentContainer {
            width: 100% !important;

            @media (max-width: 768px) {
                align-items: center !important;
            }

            .headline {
                font-size: 34px !important;
                line-height: 40px !important;

                @media (max-width: 768px) {
                    text-align: center !important;
                }
            }

            .contentOne,
            .contentTwo {
                font-size: 14px !important;

                @media (max-width: 768px) {
                    text-align: center !important;
                }
            }
        }
    }

    .leveragingContainer {
        padding: 8% 6% !important;
        height: fit-content !important;
        // flex-direction: column !important;

        .bgLogo5,
        .bgLogo6 {
            display: none !important;
        }

        .leverageRightContainer {
            width: 50% !important;


            .leveImgOneContainer {
                width: 100% !important;

                img {
                    width: 100% !important;
                }

                .whiteSpace,
                .orangeBox {
                    width: 100% !important;

                }
            }

            .leveImgTwoContainer {
                display: none !important;

            }
        }

        .leverageLeftContainer {
            width: 50% !important;
            padding-top: 28px !important;

            .levageTitle {
                font-size: 28px !important;
                text-align: center !important;
            }

            .levageParah {
                font-size: 10px !important;
                text-align: center !important;
            }
        }
    }

    .empowerContainer {
        height: fit-content !important;
        flex-direction: column !important;
        padding: 8% 6% !important;

        .leftContainer {
            width: 100% !important;
            gap: 18px !important;
            align-items: center !important;
            padding-bottom: 20px !important;

            .empowerTitle {
                text-align: center !important;
                font-size: 28px !important;
            }

            span {
                font-size: 28px !important;

            }

            .empowerContent {
                font-size: 12px !important;
                text-align: center !important;
            }

            .viewAllButton {
                height: 30px !important;
                font-size: 12px !important;
            }
        }

        .rightContainer {
            width: 100% !important;
        }
    }

    .bgLogo1,
    .bgLogo2,
    .bgLogo3,
    .bgLogo4 {
        display: none !important;
    }
}

@media (max-width: 780px) {
    .headerContainer {
        gap: 10px !important;
        height: fit-content !important;
        padding: 8% 6% !important;

        .leverTitle {
            font-size: 20px !important;
        }

        span {
            font-size: 20px !important;

        }

        .adventureTitle {
            font-size: 12px !important;

        }

        .nglccImageContainer {
            img {
                height: 50px;
                width: 150px;
            }
        }

        .buttonsGroup {
            padding: 20px 40px;
            flex-direction: column !important;

            .buttonOne,
            .buttonTwo {
                width: 100% !important;
                font-size: 12px !important;
            }

        }
    }

    .advantEdgeContainer {
        padding: 8% 6% !important;
        height: fit-content !important;
        flex-direction: column;

        .contentContainer {
            align-items: center !important;
            width: 100% !important;

            .headline {
                font-size: 24px !important;
                text-align: center !important;
                line-height: 40px !important;
            }

            .contentOne,
            .contentTwo {
                text-align: center !important;
                font-size: 12px !important;
            }
        }
    }

    .leveragingContainer {
        padding: 8% 6% !important;
        height: fit-content !important;
        flex-direction: column !important;

        .bgLogo5,
        .bgLogo6 {
            display: none !important;
        }

        .leverageRightContainer {
            width: 100% !important;

            .leveImgOneContainer {
                width: 100% !important;

                img {
                    width: 100% !important;
                }

                .whiteSpace,
                .orangeBox {
                    width: 100% !important;

                }
            }

            .leveImgTwoContainer {
                display: none !important;
            }
        }

        .leverageLeftContainer {
            width: 100% !important;
            padding-top: 28px !important;

            .levageTitle {
                font-size: 28px !important;
                text-align: center !important;
            }

            .levageParah {
                font-size: 10px !important;
                text-align: center !important;
            }
        }
    }

    .empowerContainer {
        height: fit-content !important;
        flex-direction: column !important;
        padding: 8% 6% !important;

        .leftContainer {
            width: 100% !important;
            gap: 18px !important;
            align-items: center !important;
            padding-bottom: 20px !important;

            .empowerTitle {
                text-align: center !important;
                font-size: 28px !important;
            }

            span {
                font-size: 28px !important;

            }

            .empowerContent {
                font-size: 12px !important;
                text-align: center !important;
            }

            .viewAllButton {
                height: 30px !important;
                font-size: 12px !important;
            }
        }

        .rightContainer {
            width: 100% !important;
        }
    }

    .bgLogo1,
    .bgLogo2,
    .bgLogo3,
    .bgLogo4 {
        display: none !important;
    }
}